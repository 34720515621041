<template>
  <div class="page">
    <div class="title">到院就诊</div>
    <div class="tab-area">
      <div class="tab" :class="active == 0 ? 'tab-active' : ''" @click="active = 0">
        待就诊
      </div>
      <div class="tab" :class="active == 1 ? 'tab-active' : ''" @click="active = 1">
        进行中
      </div>
      <div class="tab" :class="active == 2 ? 'tab-active' : ''" @click="active = 2">
        已完成
      </div>
    </div>
    <div class="list">
      <div class="list-item">
        <div class="top">
          <span>订单号：</span>
          <span></span>
        </div>
        <div class="content">
          <div class="img-area">
            <img class="img" src="../../../static/shop/banner.png" />
          </div>
          <div class="info-area">
            <div class="info-title">
              沙宣菲海氏海诺一次性医用外科口罩熔喷布无纺布三层沙宣菲海氏沙宣…
            </div>
            <div class="info-desc">规格：EVE止痛药蓝色40粒</div>
          </div>
          <div class="user-area">小红薯</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      params: {
        limit: 5,
        page: 1,
        show_status: 0
      }
    };
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      this.$request.orderlist(this.params).then(res => {
        console.log(res)
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.page {
  width: 910px;
  padding: 10px 30px;
  min-height: 620px;
  background: #ffffff;
}
.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: left;
  height: 36px;
  line-height: 36px;
}

.tab-area {
  width: 384px;
  height: 40px;
  background: #f6f4f4;
  display: flex;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  margin: 5px 0;
  .tab {
    width: 128px;
  }
  .tab:hover {
    cursor: pointer;
  }
  .tab-active {
    background: #fd9852;
    color: #fff;
  }
}

.list {
  margin: 20px 0;
  .list-item {
    height: 170px;
    border: 1px solid #e6e7e9;
    .top {
      height: 32px;
      background: #f6f4f4;
      line-height: 32px;
      padding: 0 20px;
      font-size: 14px;
    }
    .content {
      height: 138px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      .img-area {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        .img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }
      }
      .info-area {
        width: 220px;
        .info-title {
          font-size: 14px;
          font-weight: bold;
          color: #333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
        }
        .info-desc {
          font-size: 12px;
          color: #999;
        }
      }
      .user-area {
        width: 160px;
        text-align: center;
      }
    }
  }
}
</style>
